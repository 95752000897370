* {
    margin: 0;
    padding: 0;
    color: #31CE63;
    font-family: "Roboto", "Arial", sans-serif;
}

p{
    font-size: 1.5em;
}


/*html,
body {
    /* background-color: #5f5f5f; 
    /* overflow: hidden; 
}*/

.canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    z-index: -1;
}


/* .content {
    position: relative; 
    z-index: 1; 
    color: white; 
    text-align: center; 
    padding: 200px;
} */



.grid_container{
    display: grid;
    gap: 20px;
}

.grid_box{
    display: grid;
    place-items: center;
}

.left{
    padding-left: 200px;
    text-align: left;
}


.right{
    text-align: right;
}

.navbar {
    position: fixed; /* Фиксируем элемент на экране */
    top: 0; /* Позиционируем элемент в самом верху */
    width: 100%; /* Задаем элементу ширину 100%, чтобы он тянулся на всю ширину экрана */
    background-color: #181818; /* Задаем фоновый цвет меню, например, темно-серый */
    color: white; /* Задаем цвет текста в меню */
    padding: 10px 0; /* Добавляем немного отступа сверху и снизу */
    z-index: 1000; /* Устанавливаем z-index, чтобы меню было поверх других элементов */
  }
  
  /* Добавляем стили для ссылок в меню для улучшения внешнего вида */
  .navbar a {
    color: white; /* Цвет текста */
    padding: 14px 20px; /* Отступы внутри ссылок */
    text-decoration: none; /* Убираем подчеркивание текста */
    font-size: 17px; /* Размер шрифта */
  }
  
  /* Меняем стиль ссылки при наведении */
  .navbar a:hover {
    background-color: #ddd; /* Цвет фона */
    color: black; /* Цвет текста */
  }

 /* Стили для начального экрана */
 .first-screen {
    height: 100vh; /* Полная высота вьюпорта */
    /* background: #f0f0f0; Фон первого экрана, выберите нужный цвет */
    /* Другие стили для первого экрана по вашему выбору */
  }

  /* Стили для контейнера с шкалой времени и текстовым контентом */
  .container {
    display: flex; /* Используем Flexbox для расположения элементов в ряд */
  }

  /* Стили для шкалы времени */
  .timeline {
    flex: 0 0 auto; /* Шкала времени не должна растягиваться или сжиматься */
    width: 60px; /* Ширина шкалы времени */
    /* background: #333; Фон шкалы времени, выберите нужный цвет */
    color: white;
    position: sticky;
    top: 0; /* Закрепляем шкалу времени в верхней части экрана */
    
    /* writing-mode: vertical-rl; */
  }

  /* Стили для текстового контента */
  .content {
    flex: 1; /* Контент должен занимать оставшееся пространство */
    padding: 20px; /* Отступы вокруг текста */
    /* overflow: auto; Если контент выходит за рамки, добавляется прокрутка */
    height: 100vh; /* Высота, чтобы соответствовать высоте шкалы времени */
  }

  .year {
    text-align: center; /* Выравнивание текста года по центру */
    font-size: 24px; /* Размер шрифта года */
    padding: 10px 0; /* Отступы вокруг текста года */
    /* border-bottom: 2px solid white; Черточка под текстом года */
    height: 50vh;
  }
  
  /* Правильно выровненный вертикальный текст */
  .timeline .year {
    /* transform: rotate(-180deg); */
    text-orientation: upright;
  }



  .site-footer {
    background-color: #333; /* Темный фон для футера */
    color: #fff; /* Белый текст */
    padding: 20px 0; /* Паддинг сверху и снизу */
    font-family: 'Arial', sans-serif; /* Шрифт */
  }
  
  .footer-content {
    text-align: center; /* Выравнивание по центру */
    margin-bottom: 10px; /* Отступ снизу */
  }
  
  .contact-info {
    list-style: none; /* Убираем стандартные маркеры списка */
    padding: 0; /* Убираем паддинг списка */
  }
  
  .contact-info li {
    display: inline; /* Элементы списка в ряд */
    margin-right: 10px; /* Отступы между элементами списка */
  }
  
  .contact-info a {
    color: #fff; /* Белый цвет ссылок */
    text-decoration: none; /* Убираем подчеркивание */
    transition: color 0.3s; /* Плавный переход цвета */
  }
  
  .contact-info a:hover {
    color: #ffd700; /* Цвет при наведении */
  }
  
  .footer-author {
    text-align: center; /* Выравнивание по центру */
    font-size: 0.8em; /* Меньший размер шрифта для копирайта */
  }


  .full-page-button-container {
    height: 100vh;
    display: flex;
    color: #34db69;
    flex-direction: column; /* Элементы теперь идут в колонку */
    justify-content: center; /* Центрирование по вертикали */
    align-items: center; /* Центрирование по горизонтали */
    text-align: center; /* Центрирование текста внутри контейнера */
  }

  .button-container {
    text-align: center; /* Центрирование кнопки по горизонтали */
    /* padding: 20px; Отступ для контейнера */
  }
  
  .transition-button {
    display: inline-block; /* Позволяет задать отступы и ширину */
    background-color: #34db69; /* Цвет фона кнопки */
    color: rgb(0, 0, 0); /* Цвет текста кнопки */
    text-decoration: none; /* Убираем подчёркивание у ссылки */
    padding: 10px 30px; /* Внутренние отступы вокруг текста */
    border-radius: 5px; /* Скругление углов кнопки */
    font-size: 16px; /* Размер шрифта */
    font-weight: bold; /* Жирность текста */
    border: 3px solid #000000;
    transition: background-color 0.3s, transform 0.2s; /* Плавный переход для фона и трансформации */
  }
  
  .transition-button:hover,
  .transition-button:focus {
    background-color: #000000; /* Цвет фона кнопки при наведении и фокусе */
    border: 3px solid #34db69;
    color: #ffffff;
    text-decoration: none; /* Убедитесь, что подчеркивание текста удалено */
    /* transform: translateY(-3px); Небольшой подъем кнопки при наведении */
  }
  
  .caption p {
    margin: 0 0 20px; /* Отступ снизу для надписи */
    font-size: 24px; /* Размер шрифта надписи */
    color: #31CE63 !important; /* Цвет текста надписи */
    /* Другие стили для надписи по вашему выбору */
  }


  #scrollToTopButton {
    position: fixed; /* Фиксируем кнопку на экране */
    bottom: 20px; /* Отступ снизу */
    right: 20px; /* Отступ справа */
    background-color: #34db69; /* Цвет фона кнопки */
    color: rgb(0, 0, 0); /* Цвет текста */
    padding: 10px; /* Внутренние отступы вокруг текста */
    border-radius: 5px; /* Скругление углов кнопки */
    cursor: pointer; /* Форма курсора указывает на возможность клика */
    font-size: 16px; /* Размер шрифта */
    transition: opacity 0.3s; /* Плавное изменение прозрачности */
    z-index: 1000; /* Убедимся, что кнопка находится над другими элементами */
  }
  
  #scrollToTopButton:hover {
    background-color: #34db69; /* Цвет фона при наведении */
  }
  
  /* Показываем кнопку только при прокрутке вниз */
  .showScrollButton {
    display: block !important; /* или 'inline', в зависимости от нужного вам отображения */
  }
  
  
  